export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;

const AUTH = `${SERVER_URL}/v1/auth`;
const SERVICES = `${SERVER_URL}/v1/services`;
const INTEGRATIONS = `${SERVER_URL}/v1/integrations`;
const PROFILE = `${SERVER_URL}/v1/profile`;
const USER = `${SERVER_URL}/v1/user`;
const CALLS = `${SERVER_URL}/v1/calls`;
const PAYMENTS = `${SERVER_URL}/v1/payments`;
const PORTALS = `${SERVER_URL}/v1/portal`;
const DATABASE = `${SERVER_URL}/v1/database`;
const WORKFLOWS = `${SERVER_URL}/v1/workflows`;
const CUSTOMER = `${SERVER_URL}/v1/customer`;
const TICKETS = `${SERVER_URL}/v1/tickets`;
const WORKSPACE = `${SERVER_URL}/v1/workspace`;
const LAYOUT = `${SERVER_URL}/v1/layout`;
const PORTAL_EVENT = `${SERVER_URL}/v1/portal-events`;
const PORTAL_PAGES = `${SERVER_URL}/v1/pages`;
const PUBLIC_PORTAL = `${SERVER_URL}/v1/public/portal`;

export const URLS = {
  LOGIN: `${AUTH}/login`,
  LOGOUT: `${AUTH}/logout`,
  REGISTER: `${AUTH}/register`,
  VERIFY_EMAIL: `${AUTH}/verify/email`,
  GOOGLE_CONNECT: `${AUTH}/google-connect`,
  GET_SESSION: `${AUTH}/session`,
  CHECK_USERNAME: `${AUTH}/check/username`,
  CHANGE_USERNAME: `${AUTH}/change/username`,

  UPDATE_TIMEZONE: `${SERVICES}/timezone`,
  GET_AVAILABILITY: `${SERVICES}/availability/default`,
  UPDATE_AVAILABILITY: `${SERVICES}/availability`,
  USER_SCHEDULE_DATA: `${SERVICES}/public`,
  FETCH_ALL_SERVICES: `${SERVICES}/all`,
  CREATE_SERVICE: `${SERVICES}/`,

  USER_DATA: `${PROFILE}/public`,
  UPDATE_USER_NAME: `${USER}/me`,
  UPDATE_USER_IMAGE: `${USER}/me/displayImage`,
  PAGE_VISITS: `${USER}/me/metadata/visits`,

  /* CALLS */
  NEW_CALLS: `${CALLS}/requests`,
  PAST_CALLS: `${CALLS}/past`,
  PENDING_PAYMENTS: `${CALLS}/pending-payments`,
  WITHDRAWABLE_PAYMENTS: `${CALLS}/withdrawable-payments`,

  /* INTEGRATIONS */
  GOOGLE_CALENDAR_OAUTH_URL: `${INTEGRATIONS}/calendar/google/authorize`,
  GOOGLE_CALENDAR: `${INTEGRATIONS}/calendar/google`,
  GET_CALENDAR_CONNECTED: `${INTEGRATIONS}/calendar/connected`,
  UPDATE_CALENDAR_CONNECTED: `${INTEGRATIONS}/calendar/options`,
  UPDATE_CALENDAR_HOST: `${INTEGRATIONS}/calendar/host`,

  /* PAYMENT */
  CREATE_STRIPE_INTENT: `${PAYMENTS}/create-payment-intent`,
  UPI_INFO: `${PAYMENTS}/upi`,

  /* PORTALS */
  GET_PORTALS_LIST: `${PORTALS}/list`,
  PORTAL: `${PORTALS}`,
  UPDATE_PORTAL_SUBDOMAIN: `${PORTALS}/update-subDomain`,
  IS_SUB_DOMAIN_AVAILABLE: `${PORTALS}/isSubDomainAvailable`,
  UPDATE_PORTAL_LOGO: `${PORTALS}/logo`,
  UPDATE_PORTAL_THEME: `${PORTALS}/theme`,
  UPDATE_PORTAL_BASIC_INFO: `${PORTALS}/basic-info`,

  /* DATABASE */
  FETCH_COLLECTION_DATA: DATABASE,
  FETCH_COLLECTION_LIST: `${DATABASE}/list`,

  /* WORKFLOWS */
  FETCH_WORKFLOW_LIST: `${WORKFLOWS}/list`,
  FETCH_WORKFLOW_DATA: `${WORKFLOWS}/slug`,
  UPDATE_WORKFLOW_ACCESS_TYPE: `${WORKFLOWS}/accessType`,
  CREATE_WORKFLOW: `${WORKFLOWS}`,
  PUBLISH_WORKFLOW: `${WORKFLOWS}/publish`,

  /* CUSTOMER */
  FETCH_CUSTOMER_USER_LIST: `${CUSTOMER}/users/list`,
  FETCH_CUSTOMER_USER_INFO: `${CUSTOMER}/users/info`,
  ADD_CUSTOMER_USER: `${CUSTOMER}/users`,
  UPDATE_LOGIN_CONFIG: `${CUSTOMER}/users/config`,
  SEND_MAGIC_LINK: `${CUSTOMER}/users/login/send-magic-link`,
  STATUS_UPDATE: `${CUSTOMER}/users/status`,
  ASSIGN_WORKFLOW: `${CUSTOMER}/users/assign-workflow`,
  UNASSIGN_WORKFLOW: `${CUSTOMER}/users/unassign-workflow`,

  /* TICKETS */
  FETCH_TICKETS_LIST: `${TICKETS}/list`,
  UPDATE_TICKETS: TICKETS,

  /* WORKSPACE */
  SWITCH_WORKSPACE: `${WORKSPACE}/change`,

  /* PUBLIC_PORTAL */
  GET_PORTAL_DETAILS: `${PUBLIC_PORTAL}/info/slug`,

  /* LAYOUT */
  GET_LAYOUT: LAYOUT,

  /* PORTAL EVENTS */
  GET_PORTAL_EVENTS: PORTAL_EVENT,

  /* PORTAL PAGES */
  GET_PORTAL_PAGES: PORTAL_PAGES,
};
